import React from "react"
import "./styles.scss"
import ArticleCard from "../ArticleCard"

const ArticleList = ({ articles }) => {
  return (
    <ul className="card-list">
      {articles.map((article, i) => (
        <ArticleCard article={article} key={i} />
      ))}
    </ul>
  )
}

export default ArticleList
