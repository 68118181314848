import React from "react"
import Card from "../Card"

const ArticleCard = ({ article }) => {
  return (
    <Card
      key={article.slug}
      card={{
        url: `/${article.category.slug}/${article.slug}`,
        title: article.title,
        img: { ...article.coverImage.fixed, title: article.coverImage.title },
      }}
    />
  )
}

export default ArticleCard
