import React from "react"
import { Link } from "gatsby"
import { RichText } from "../RichText"
import PropTypes from "prop-types"
import "./styles.scss"

const Card = ({ card }) => (
  <li className="card" key={card.url}>
    <Link to={`${card.url}`}>
      <div className="card-image">
        <img src={card.img.src} alt={card.img.title} />
      </div>
      <h4 className="underlined">{card.title}</h4>
      {card.description && <RichText content={card.description} />}
      {card.summary && <p>{card.summary}</p>}
    </Link>
  </li>
)

Card.propTypes = {
  card: PropTypes.shape({
    url: PropTypes.string.isRequired,
    img: PropTypes.shape({
      src: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    description: PropTypes.object,
    summary: PropTypes.string,
  }),
}

export default Card
