import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleList from "../components/ArticleList"
import CategoryCard from "../components/CategoryCard"

const IndexPage = ({ data }) => {
  const articles = data.allContentfulArticle.nodes
  const categories = data.allContentfulCategory.nodes

  return (
    <Layout>
      <SEO title="Startsida" />
      <h2 className="section-title">Utvalda artiklar</h2>
      <ArticleList articles={articles} />

      <h2 className="section-title">Kategorier</h2>
      <ul className="card-list">
        {categories.map((category, i) => (
          <CategoryCard key={i} category={category} />
        ))}
      </ul>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    allContentfulCategory(limit: 20) {
      nodes {
        slug
        name
        description {
          json
        }
        summary {
          summary
        }
        coverImage {
          fixed(width: 300, height: 200) {
            src
          }
          title
        }
      }
    }
    allContentfulArticle(limit: 6, sort: { fields: updatedAt, order: DESC }) {
      nodes {
        slug
        title
        category {
          slug
        }
        coverImage {
          fixed(width: 300, height: 200) {
            src
          }
          title
        }
      }
    }
  }
`
