import React from "react"
import Card from "../Card"

const CategoryCard = ({ category }) => (
  <Card
    card={{
      url: `/${category.slug}`,
      title: category.name,
      img: { ...category.coverImage.fixed, title: category.coverImage.title },
      summary: category.summary.summary,
    }}
  />
)

export default CategoryCard
